enum responseStatus {
    success = 200 ,
    unauthorized = 401 ,
    unauthorizeddevice = 403 ,
    error_parameters = 999 ,
    error_user_permissions = 995,
    error_device_type = 994 ,
    error_user_account_password = 991 ,
    error_user_password = 992 ,
    error_user_NotActive = 989 , 
    error_router_NotBSSID = 980 ,
    error_user_router_exist = 979 ,
    error_user_NotExist = 990 ,
    error_timer_limit = 990 ,
    error_timer_conflict = 978 ,
    error_timer_type = 977 ,
}

export default responseStatus;