import { ILoginParameter, ILoginResult } from "@interfaces/Data/ILogin";
import baseApi from "@redux/features/baseApi";
import { getLocalStore, getSession } from "@utils/Session";


export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login:build.mutation<ILoginResult , ILoginParameter>({
      query:(data) => {
        
        return ({
          url:'/apis/auth/login' ,
          method:'POST' ,
          body:data
        })
      }
    }) ,
    logout:build.mutation<void , void>({
      query:() => {
        const refreshToken = getSession("_RSID") || getLocalStore("_RSID");
        return (
          {
            url:'/apis/auth/logout' ,
            method:'POST' ,
            body:{refreshToken:refreshToken}
          }
        )
      }
    }) ,
    refreshToken:build.mutation<ILoginResult , void>({
      query:() => {
        const refreshToken = getSession("_RSID") || getLocalStore("_RSID");
        return (
          {
            url:'/apis/auth/refreshToken' ,
            method:'POST' ,
            body:{refreshToken:refreshToken}
          }
        )
      }
    }) 
  }),
});

export const { useLoginMutation , useLogoutMutation , useRefreshTokenMutation } = authApi;