import ICopyrightProps from "@interfaces/Props/ICopyrightProps";
import { Typography } from "@mui/material"

const Copyright = ({ sx } : ICopyrightProps) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={sx}>
            {'Copyright © iOShop 2023.'}
        </Typography>
    )
}

export default Copyright;
