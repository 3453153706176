import { 
    CssBaseline , 
    Container ,
    Box ,
    Avatar ,
    Checkbox ,
    TextField ,
    FormControlLabel ,
    Typography , 
} from "@mui/material";
import Lock from "@icon/Lock";
import { useForm , Controller } from "react-hook-form";
import Copyright from "./Copyright";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoginMutation } from "@redux/features/Auth/authApi";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import responseStatus from "@enums/responseStatus";
import { ErrorMessage , InfoMessage, SuccessMessage, WarningMessage } from "@components/AlertMessage";
import { setLocalStore, setSession } from "@utils/Session";

const LoginLayout = () => {
 

    const [
        loginUser , 
        {
            isLoading ,
            data:loginResult , 
            isSuccess:isLoginSuccess ,
            isError:isLoginError ,
            error:loginError
        }
    ] = useLoginMutation();
    
    const navigate = useNavigate(); 
    

    const validationSchema = Yup.object().shape({
        account: Yup.string()
        .required("請輸入帳號"),
        password: Yup.string()
        .required("請輸入密碼")
    });

    const {register , control , handleSubmit , formState:{errors} , getValues} = useForm({
        resolver: yupResolver(validationSchema) ,
        defaultValues: {
            account : "",
            password : "" ,
            remember: false,
        }
    });

    const onSubmit = handleSubmit((data) => {
        
        if(data){
            loginUser(data!!);
        }
        
    });


    useEffect(()=>{
        if(isLoginSuccess){
            
            switch(loginResult?.code){
                case responseStatus.success :
                    SuccessMessage(`成功登入`);
                    setSession("_SID" , loginResult.token);
                    if(getValues("remember")) { 
                        setLocalStore("_RSID", loginResult.refreshToken); 
                    } else {
                        setSession("_RSID", loginResult.refreshToken); 
                    }
                    navigate("/admin", {replace: true});
                    break;
                case responseStatus.error_user_account_password :
                    InfoMessage(`帳號密碼錯誤`);
                    break;
                case responseStatus.error_user_permissions :
                    InfoMessage(`此帳號無管理員權限`);
                    break;
                default :
                    WarningMessage(`未知錯誤`);
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [isLoginSuccess , loginResult ]);

    useEffect(()=>{
        if(isLoginError){
            console.log(loginError);
            ErrorMessage(`伺服器忙碌中...請稍後在試...`);
        }
        
    } , [isLoginError , loginError]);

    return (
        <Container component="main" maxWidth="xs" sx = {{display:"grid" , height:"100vh" , justifyContent: 'center' , alignItems: 'center'}} >
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.light' }}>
                    <Lock />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form"  onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        id="account"
                        {...register("account")}
                        margin="normal"
                        required
                        fullWidth
                        label="Account"
                        name="account"
                        autoComplete="account"
                        autoFocus
                        error={ errors.account ? true : false}
                        helperText={ errors.account ?  errors.account?.message?.toString() : "" }
                    />
                    <TextField
                        id="password"
                        {...register("password")}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        error={ errors.password ? true : false}
                        helperText={ errors.password ?  errors.password?.message?.toString() : "" }
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name="remember"
                                control={control}
                                render={({ field }) => <Checkbox color="primary" {...field} />}
                            />
                            
                        }
                        label="Remember me"
                    />
                    {
                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            >
                            Sign In
                        </LoadingButton>
                    }
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Box>
            </Box>
            
        </Container>
    )
}

export default LoginLayout;