import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from "@reduxjs/toolkit/query/react"
import { authApi } from "@redux/features/Auth/authApi"
import userSlice from '@redux/features/User/userSlice';
import menuSlice from './features/Menu/menuSlice';


export const store = configureStore({
  reducer: {
    [authApi.reducerPath]:authApi.reducer ,
    user: userSlice,
    menuState: menuSlice,
  },
  devTools:process.env.NODE_ENV === 'development' ,
  middleware:(getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware) ,
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);
