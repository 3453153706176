import { IRoutersResult } from "@interfaces/Data/IRouter";
import { IUser, IUserAccountPasswordParameter } from "@interfaces/Data/IUser";
import baseApi from "@redux/features/baseApi";


export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile:build.mutation<IUser ,void>({
      query:() => {
        return ({
          url:'/user/info' ,
          method:'get' ,
        })
      }
    }) ,
    getUserManagementFields:build.mutation<IRoutersResult ,void>({
      query:() => {
        return ({
          url:'/router/data' ,
          method:'get' ,
        })
      }
    }) ,
    postUserManagementField:build.mutation<IRoutersResult ,IUserAccountPasswordParameter>({
      query:(data) => {
        return ({
          url:'/user/router' ,
          method:'post' ,
          body:data
        })
      }
    }) ,
  }),
});

export const { useGetUserProfileMutation , useGetUserManagementFieldsMutation , usePostUserManagementFieldMutation } = userApi;