import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const menuStateSlice = createSlice({
    name: "menuState",
    initialState : {
        menuState:""
    } as {
        menuState:string
    },
    reducers: {
        setMenuState: (state, action: PayloadAction<string>) => {
            state.menuState = action.payload;
        }
    }
});

export const {
    setMenuState
} = menuStateSlice.actions;

export const selectMenuState = (state:RootState) => state.menuState.menuState;

export default menuStateSlice.reducer;