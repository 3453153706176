import { Box, CircularProgress, Container} from "@mui/material";
import { useEffect } from "react";
import { useRefreshTokenMutation } from "@redux/features/Auth/authApi";
import { useNavigate } from "react-router-dom";
import { setSession } from "@utils/Session";
import responseStatus from "@enums/responseStatus";



const LauncherLoading = () => {

    const [
        
        refreshTokenApi , 
        {
            data:result , 
            isSuccess:isRefreshTokenSuccess ,
            isError:isRefreshTokenError ,
            error:refreshTokenError
        }
    ] = useRefreshTokenMutation();

    const navigate = useNavigate();
    
    useEffect(()=>{
        refreshTokenApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , []);

    useEffect(()=>{
        if(isRefreshTokenSuccess){
            
            switch(result?.code){
                case responseStatus.success :
                    setSession("_SID" , result.token);
                    navigate("/admin", {replace: true});
                    break;

                default :
                    navigate("/login", {replace: true});
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [isRefreshTokenSuccess , result ]);

    useEffect(()=>{
        if(isRefreshTokenError){
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [refreshTokenError , isRefreshTokenError]);

    

    return (
        
        <Container
            disableGutters
            sx = {{
                height:"100vh",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center"
            }}           
        >
            <Box sx = {{display: 'flex' , justifyContent:"center",alignItems:"center"}}>
                <CircularProgress color="secondary" />
            </Box>
            
        </Container>
    );
}
export default LauncherLoading;