const Logo = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="92px" height="32px" viewBox="0 0 92 32" enableBackground="new 0 0 92 32" xmlSpace="preserve">  <image id="image0" width="92" height="32" x="0" y="0"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAAAgCAYAAACfDx/iAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
        AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
        CXBIWXMAABJ0AAASdAHeZh94AAAAB3RJTUUH5wMJCSM0bfEMmwAAEBFJREFUaN7tmnl0nWWdxz/v
        cu971+QmN1vTpM3SNjRdaLrRUmhZClTRAYswOCMqyjAeZzw4io4c6XjcEc+ggo7osOl4VBRFhkUK
        tlCsdC+ZpOmWhrTZ15t7c9f3vsszf7xpktsktIpYmfF7zj33nvdZ3+/v9/y250pCCMEYzJ5+Ur/d
        SXLrTjJ7GrGHR5Hz/XjWLCN0xwfxrl3BX/HmIJ0mPPXSLqIP/JD09j0IPQuS5HyEAFugVpdT8p3P
        47vykvO957c1ZAC9sYXk1h3YiRQikwVZdsgG51uRMdq6iHzjIex48nzv+W0NGSC1Yze+Ky5GJFOA
        mLajpMgY7Z1Y0dj53vPbGjK2jZ3J4JozG1d1JcK2p+0obIFrzmyUUN5ZJxV6duy06I5J+ivGoSLL
        uCpmk2lsIfTJj6C3tJI93IZ02oYDSBKSSyF487XIwcC0Exltp0i9spfMvibMrl5EMoPk1VBnlaCt
        WIxvw0W462occ/X/GJIQQtjRUUa++xjaojqUshISTzxHZvf/YEVGARs7kcJ31TpK7rsbOT+YM4HZ
        3UfsBz8j/vNnMTt6EaYFSOP+FgSSoqDMKiZw/UZCH3s/rtq557Q50xYk0ga6YSGEQFVlAh4XHpeS
        008IQTxjYtkCSYKApqIqf5mCHY9S7Ogo8ae2YvUOIofyEakM1nAEoetoFy4kcN01yHkT2i1Mk8Sv
        X2DkvofJvnbUeSjLYNsI4by4sEGSpbHnAiFstCULKLr3s/guXzPjpg51jvB8Yxd72gZpH0wQTxsg
        BG6XwuwCH0sqC3hnQwUb6mchSxLRZJbbH/o9J/pH8btVHvjQGpZVhc83t9NCPf1DDuWR/8EbSe3Y
        jd50FGEaqLNLUWeXoYQLEIY5QXY6Q+TrDxL9zo8RiRQoYxonBO7F8/FtWo86uwTjWDvJ53ZgdvSC
        LCOhoDcdZ+CjWyh96Kt4L12VsxlbCB7efpwvPtlIVyQF1pg/kSYJo2OErY1dZE2Ly+rLATBtm+au
        EY52RtE8KvGMcb55PTvhAPZogsiXv4t+sGWCRNsGWabs0XvxX3s5WDYj336UkW8+CoaVQ7b/PVdR
        fM+nUSvLx+fM++ANDN7xJTJ7mxzSVQXjZDdDW+5j1s/uRy0rHu+769gAdz1+gOGEjqrKrJxXTMPc
        QsJ+jVjG4ORgnMM9MdK6yfvW1Y67GABFkkCWUGTJ8T9/oVAdouOkd7+Gu64Gs7sfkdThtA0UOL9t
        J9pIvbyLxDPbwGbCqdo2rkXzKPranTlkA2jL6gl/7U56b74DeyjqOGBVQd/bTPzxZyi449bxvs81
        djIcz4Ak8f61Ndx3y2oKAtp4u2Ha9EZTdEeSLK8umvaFpLFPfzRNc0eEkVSW0nwviypChIOecyJF
        CHgzMnuj8SqA2TOAfvAQSlEhdjQO8qTe0sRHZHSSL7yChASmOR5xCFvgv/YyXHNmT7uIZ/WFeNc2
        kPj1NiR14uQknvot+bfeiJwXQAhB70hqfM3L68tyyAZwqTJzigLMKZo+UgJHLx7afoz97cOcGIhj
        WAKPS2ZReYhPvKOem9bWoMhT2RiOZ/j9sX72tg3RM5JCliQqw37WzCtmzYIS8n3unP6N7cO8dLgX
        VZG4bsVcygt97Djcx7ZDPfTF0uR5XSypLOTyRWXUlE6E0g7h/YMopUVYQyOIVHp68cgy+uHjCMvG
        juVmm5Kq4KqtmpEESVVx1cwhJ6mSZYwTpzC6etHq5yNJEkHv2EsJeOa1TjYunU15ge8P0q6MYfHY
        zjawxLjtT1o2e18f4h8efpV42uD2jRdM0kbBMwc6uefpJva3D5PVzYltyuDxuFg3v4Qt11/IhkWz
        xsf97kgvn/yvPbhdKsUBD/vbh/ne9mOkktkJRVVlaouDfPzqhdy+sQ6vW3UIt/oGUUuKMLv6ELox
        fawsSWSPteOqKCOZTOYKxbbHstSZYSfObJfGIqGR8Sfr6kr53vZjGJbglwc6ONo7ypX1ZaxfWEZD
        VZiKsP+s4Z4ANFXh3asqWFldxHAiw6/2d9A2ECeZMbnvNy28Y1kFlWOn5LGXW/nUT/YxEtfRNJU1
        daXML8vDFoIj3VGaOqNsa+7haE+U73/4Yq5dMWeCUEnCluDLTzVxtDfG/NI81l5UhU9TOdwVZffr
        Q7T1j3LnT/cTSWS4+4aG0xo+hHdeFZl9TQjbRpqOcCGw43GUosKpTbYg/eoB8m/7WySXOqXdGoqg
        H2hGks6YVyJHuNcur+T2yxbwg5ePYxgWzZ0jNHdE+O62Y8wu8LGyOszVS2azcUk51SVBpoUtuPni
        Kr5328V43c5eNi2t4MYHXiaS1Dk1nORwd4zKogD72wa5+4mDjCR0ZoX9fOXG5WxePZc8nxsEDCcy
        /Ph3bXzhyUa6Iyn+9WcHqK8soLok6BweyckVWnqi3LZ+PltuWDZu7pK6ya/2nuQzP91PXzTNt7Ye
        YcmcQmRhmtjxBEphCKOj5w21ByGQ8/NQCkI5KbukyKRe2Eniv1+cdkzskZ+jNx+fcMRjuigH/KjF
        EwL0ayr3/v0qHr5tHRuXlBMOao7pMSxODsR5Ys9J/vHRXVz9ta38xwtHMKypZQi3KnPTmupxsgEu
        WlBCfXk+CNAN23HMwA9fOUFPJIXbpfCFzcu49bL55Pvcp5WXoqCHT7xzEZ+4ZiHIMi3dUX655+QU
        Aa+vK+Wev1uZ41v8msotl87jc9ctRVFkRtMG3992DFUkUoisgaS5Mbv6ZnavkoQcCIAE2vJ69EPH
        J06CJCFGkwx95utYPQP4r70cOS+INTDE6E+eIvaDx52YetLcwrJx19eiVszKWcanqdyyfh6bL6ri
        eE+M/a8Psat1gAPtw7QOxEnrJif643zqJ/sIeFQ+sH5+znhVkcnz5zo4TZUd4QmBkBwliKcNXm0d
        AAELyvK4buWcGfXsxjXVPLjtOH0jKV49PjBxPAUgS7xvbfWMEdD1K+dy//OHae2Pc+DkMKo1FEFy
        u0AIrIHhmWNYIXDX1ZB+9QCBzdeQ+NULiLQ+QaIsY/UMMXTXvxN94EfI+UGswQjWQGRcKDnyc6kE
        btiE7PNOu5xfU2moDtNQHea2K+oYimc48PoQ33imme1H+sjoJo/tOMF7L6omZ+bpti9JuCadLgmJ
        pG4QGXNws0I+x4zMgHDQQ77fTd9IisFEhoxh5QhzfunMBb2Q301xvpfW/jixjIls9g2iFORjjyaw
        hmMza7htoy2uwxocxlU7l8AN10ytLMpOAcXs7Cfb3OqQPbkIdlp2pon3yjUENm+askxKN6c8kyQo
        zvOwaVkFX75pOSGvGySJ7kiSeDr7BwfNAoHHpRDwOGYnktRJZ80Z+yczBqmx7DWouXKEZ9o2Qwl9
        xrEZw3JKE0DY50I2u3pRisNYgxEn0phRw0HyaHgvXU3y2W0UfvajeNevGitWnQHZubSYbi5hWWgN
        Cyn6yqemlHp3tw5w07de4kevtJKcIT03LIE9Fre5VOWPKlIJASG/xrI5jv842htj55H+Gftvbeqm
        J5YGCRqqCsfieCfstCzBk/tOoRvWtGN3HumjbSAOQMPcMLLR1YdaXorR1evc9pwFvivWgaqQ3tdI
        6YNfIrD5Kodgy5rp7mLiRU0Lz5qllP7nV9EW1+W0tffH+adHdvHsax3c/vAubvjmdh556TiHOkbo
        Gk7SPhDnN691suXnBxkdK2atrA5TENAQf2TN/f3ragn63SQzJnf/4iC/O9KHbU/MZVo2T+/v4N6n
        m7FMm7ICH5tXV+VOIkv8+mAn9z7dPK7JALYt2HG4ly1PvEZKN/F7VG7dMA/VGo6glBSR3nlg7DpN
        mbIxaZK2SqpC/kduJvbwT0lu20nRPZ/Bd/UljD76S7ItrdjJ9EQEI0lIiuJou2nivWI1Jfd9DvfC
        +VPW8GoqF80r5sRAnNFUlq1N3bzY0ks4oBH0ujBM5+imdROEYH5ZHv98TT3y2L5MW4BlY1r2NAIQ
        WLZwkiHBePuVS8r5l031fPXpZpo6Irz3/pe4enE5SysLsAQcaB/ity29RBM6Hs3FXX+zhBU1uSUF
        5zBLfOHJRnYc6ePSulLcqsyhrigvHuphcNSJiD6wrpbrV1Wh2rEEctCPf9MG3Auqp5oBWUZSFLQV
        iyce+byEPnoLyWe3E3vkcTwNiyn59haMrj6yh45jnOrGHhlFmCYik8bOZPGuW0HoY7fkFKsmoyzk
        5f4PrWHz6rk8tuMELx/toy+WZjCWZjCadjopMsV5Hi6/oJQ737WElbXOy7tVmSsXllFbFMDnVgif
        URKQkFhdXYSumyiyREWhH3Aimruuv5B8n5tvP3+YjqEEP95xYsLxCkCVWTArn0+/azEf2nCGoghw
        yzL/dt1SXjrSx9bmHrY1dU9aWCLP7+YDl9Ty+fc24HErSL0fvlO4F9Yiae6pGaZtoy2tx7dh5tq1
        2TdIeuc+jNc7xm6GXGBaCNNAmBZqWTGetcvR6uefs3MzLZu2/jiNJ4c50T/KSCqL361SXRykoTrM
        BeX5aGdcQliWPW7RFFmespRli3HNnq6ieKQrynONnU4tJZZGkSQqC32snVfCpmUV1JTmJlrf+c0h
        Pv6jvWhulefu3MiK2iKe2HOSF5u66Ywk8WkqSyoKePfySi65oAyX6nArmUMRgTW9wRcCZJ9nxmu1
        nL6mhR0bxR5NgGUj+b0ooTwk77lV6P5SYNnCcYASeFzKuMk6E5MJf+aTV7Lxwtnj4zNZE0WW8bin
        mmdVCRf8STYqqQpKuIA/1XznC4os4dNUbCFmJPts4/0e14ztb8nFXyJj8OALR3jlcB9J3XzzE/6Z
        MJrOsv1QD1/8xUFODSbesK8QgHBuqcTZwrNJUM+55x8Aj0uhrMDHQ9uPcf/zLSyrCrOurpQLyvMp
        yvPkJA7nE7ph0R9L09IZ5dXj/RzqiBDya7xn9dyzloUDmkpZyIvXpeBRlXNccdIl8luBlG6yr22Q
        p/Z1sKt1gLRhURn2s6giRH1FiOriIGUhL4UBDa9bxeNW/qhj/EawbEE6a5LMmAwndHpGUrzeP0pL
        d5TDXVF6R1IEvS4urSvl+lVzWV5TNOVfAdMhlswyHM8gAWUFPrzauenuW0r4ZPRFU+w5MciLzT3s
        bh3g5GAC3bTxaSpFAY3ifC9leR4KgxolQQ8hnxufx0We10VAU/G6FCfDk6TcsE0ITFuQyprEMyaj
        aYN0xmA4lWVgNM1IXKcnlmYorjOc0MlkTbwuhZqSIOsWlLJxSTmraosoyfe+mdc7Z/zZCJ+MaDJL
        W/8ojaciHGwfoqUrysmhJEOJjGPzbTFeiZMk52LY4dr5v8t4zCcEYiyRscRYcnN6rASSLOPXVIqD
        GlXFARZXhFheVcSFcwupKQ1OuTb7P0v4mUhkDAZiGTqHE7T1xzk5mKBjKEFPNE1/LE1KN0joFmnD
        ImvZzh9+cCICtyrjdSkE3Co+j0pZvpdZIS9zigJUFwepKQ1SGfZTku/Ff47H/q3E/wK2OhSckoHI
        /gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMy0wOVQwOTozNTo1MSswMDowMF0A4bgAAAAldEVY
        dGRhdGU6bW9kaWZ5ADIwMjMtMDMtMDlUMDk6MzU6NTErMDA6MDAsXVkEAAAAKHRFWHRkYXRlOnRp
        bWVzdGFtcAAyMDIzLTAzLTA5VDA5OjM1OjUyKzAwOjAwSqBiRgAAABl0RVh0U29mdHdhcmUAQWRv
        YmUgSW1hZ2VSZWFkeXHJZTwAAAAASUVORK5CYII=" />
        </svg>
    );
}

export default Logo;