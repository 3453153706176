import { ButtonBase } from "@mui/material";
import Logo from "@icon/Logo";


const LogoSection = () => {
    return (
        <ButtonBase>
            <Logo />
        </ButtonBase>
    )
}

export default LogoSection;