import { Box, FormControl, InputLabel, Select, MenuItem , SelectChangeEvent, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import IBasicSelectProps from '@interfaces/Props/IBasicSelectProps';
import Close from "@icon/Close";

const BasicSelect = ( { clearButton , defaultFristSelect , defaultFristLabel , defaultFristValue , label , defaultValue , items  , callback} : IBasicSelectProps) => {

    const [value , setValue] = useState<string>("");

    const handleChange = (event: SelectChangeEvent) => {

        setValue(event.target.value as string);
        callback(event.target.value as string);
    };

    const handleClearValue = () => {

        setValue("");
        callback();
    };

    useEffect(()=>{
        setValue(() => {
            return defaultValue ? defaultValue : ""
        });

    }, [defaultValue]);

    return (
        <Box 
            sx={{ 
                minWidth: 120 ,
            }}
        >
            <FormControl fullWidth>
            <InputLabel id="select-label" > { label } </InputLabel>
            
            <Select
                labelId="select"
                id="select"
                label={label}
                value={value}
                onChange={handleChange}
                sx={{ 
                    "& .MuiSelect-iconOutlined": {display: (clearButton && value) ? 'none': ''}, 
                    "&.Mui-focused .MuiIconButton-root": {color: "info"} ,
                }}
                {
                   ...(clearButton && {
                        endAdornment : (
                            <IconButton
                                size="small"
                                sx={{
                                    
                                    display: value ? "": "none"
                                }}
                                onClick={handleClearValue}
                            >
                                <Close color = "info"/>
                            </IconButton>
                        )
                   })
                }
                
            >
                {
                    defaultFristSelect && <MenuItem value={defaultFristValue ? defaultFristValue : "default" }> { defaultFristLabel ? defaultFristLabel : "請選擇"}</MenuItem>
                }
                {
                    items?.map(item => {
                        return (
                            <MenuItem key = {item.value} value={item.value}>{item.label}</MenuItem>
                        )
                    })
                }
            </Select>
            </FormControl>
        </Box>
    );
}

export default BasicSelect;