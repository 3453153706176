import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BasicSelect from "@components/select/BasicSelect";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectField, selectFields, setManagementFields, setSelectField } from "@redux/features/User/userSlice";
import { useEffect, useState } from "react";
import { IBasicSelectItem } from "@interfaces/Props/IBasicSelectProps";
import { useGetUserManagementFieldsMutation } from "@redux/features/User/userApi";
import responseStatus from "@enums/responseStatus";
import { useNavigate, useParams } from "react-router-dom";
import LogoSection from "../LogoSection";
import ProfileSection from "../ProfileSection";

const Header = () => {

    const theme = useTheme();
    const [selectItems , setSelectItems] = useState<Array<IBasicSelectItem>>();
    const dispatch = useAppDispatch();
    const fields = useAppSelector(selectFields);
    const field = useAppSelector(selectField);
    const { bssid } = useParams();
    const navigate = useNavigate(); 
    const [
        
        getUserManagementFields , 
        {
            data:fieldsResult ,
            isSuccess:isGetFieldsSuccess,
        }
    ] = useGetUserManagementFieldsMutation();

    const handleSelectFieldCallBack = (value? : string) => {
        if(value){
            navigate(`/admin/fields/${value}/record/indoor` );
        }
        
    }

    useEffect(()=>{

        if(bssid){

            const tempField = fields.find(field => {
                return field.bssid === bssid;
            });

            dispatch(setSelectField(tempField));

            if(tempField){
                navigate(`/admin/fields/${bssid}/record/indoor` );
            } else {
                navigate(`/admin` );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [bssid , fields]);

    useEffect(()=>{
        if(fields.length !== 0){
            let tempItems = fields.map( item => {
                return {
                    label : item.alias ,
                    value : item.bssid
                }
            });
            setSelectItems(tempItems);
        } 
    }, [fields]);

    useEffect(() => {
        getUserManagementFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , []);

    useEffect(()=>{
        if(isGetFieldsSuccess){
            
            switch(fieldsResult?.code){
                case responseStatus.success :
                    dispatch(setManagementFields(fieldsResult.routers));
                    break;
                
                default :
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [isGetFieldsSuccess , fieldsResult ]);



    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                
            </Box>
            
            <Box
                sx={{
                    marginLeft:5 ,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <BasicSelect defaultFristSelect = { field ? false : true} items={selectItems} defaultValue={field ? field.bssid : undefined} callback={ handleSelectFieldCallBack}  />
            </Box>
            
            <Box sx={{ flexGrow: 1 }} />
            <ProfileSection />
        </>
    )
}

export default Header;