import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithIntercept } from "./baseQuery";


const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithIntercept,
    keepUnusedDataFor: 5 * 60,
    refetchOnMountOrArgChange: 30 * 60,
    endpoints: () => ({}),
});

export default baseApi;