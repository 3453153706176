import { IRouter } from "@interfaces/Data/IRouter";
import { IUser } from "@interfaces/Data/IUser";
import { RootState } from "@redux/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {
            name:"" ,
            email:""
        } ,
        fields: [] ,
        selectField : null
    } as {
        user : IUser ,
        fields : Array<IRouter> ,
        selectField? : IRouter |null
    },
    reducers: {
        setUserProfile(state, action:PayloadAction<IUser>) {
            state.user = action.payload
        },
        setManagementFields(state, action:PayloadAction<Array<IRouter>>) {
            state.fields = action.payload
        },
        setSelectField(state, action:PayloadAction<IRouter | undefined | null>) {
            state.selectField = action.payload
        },
    },
});

export const { setUserProfile , setManagementFields  , setSelectField } = userSlice.actions;

export const selectUserProfile = (state:RootState) => state.user.user;
export const selectFields = (state:RootState) => state.user.fields;
export const selectField = (state:RootState) => state.user.selectField;

export default userSlice.reducer;