import { Box, CssBaseline , useTheme , styled } from "@mui/material";
import Sidebar from "./MenuList/Sidebar";


type Props = {
    window?: () => Window;
    children?: React.ReactNode;
};

// styles
const Main = styled('main', {})(({theme}) => ({
    ...theme.typography.subContent,
}));

const SubLayout = ({ children }:Props) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <CssBaseline />
            <Sidebar />
            <Main theme={theme} >
                {children}
            </Main>
        </Box>
    );
}

export default SubLayout;