import ISidebarItemProps from "@interfaces/Props/ISidebarItemProps";
import { ListItemButton, ListItemIcon } from "@mui/material";
import { selectMenuState } from "@redux/features/Menu/menuSlice";
import { selectField } from "@redux/features/User/userSlice";
import { useAppSelector } from "@redux/hooks";
import { NavLink } from "react-router-dom";
import util from 'util';

const SidebarItem = ({ item }: ISidebarItemProps) => {

    const menuState = useAppSelector(selectMenuState);
    const field = useAppSelector(selectField);
    
    return (
        item.sidebarProps && item.path ? (
            <ListItemButton
                component={NavLink}
                to={util.format(item.path , field ? field.bssid : "" )}
                sx={{
                    "&: hover": {
                    backgroundColor: "primary.200"
                    },
                    backgroundColor: menuState === item.state ? "primary.200": "unset",
                    paddingY: "12px",
                    paddingX: "24px"
                }}
            >
                <ListItemIcon sx={{
                    color: "orange.dark"
                }}>
                    {item.sidebarProps.icon && item.sidebarProps.icon}
                </ListItemIcon>
                {item.sidebarProps.displayText}
            </ListItemButton>
        ) : null
    );
  };
  
export default SidebarItem;