import { enqueueSnackbar } from "notistack";

export const SuccessMessage = (message:string) => {
    
    return (
        <>
            {  enqueueSnackbar({ variant: 'success', message: `${message}` }) }
        </>
    );
}

export const ErrorMessage = (message:string) => {
    
    return (
        <>
            {  enqueueSnackbar({ variant: 'error', message: `${message}` }) }
        </>
    );
}

export const WarningMessage = (message:string) => {
    
    return (
        <>
            {  enqueueSnackbar({ variant: 'warning', message: `${message}` }) }
        </>
    );
}

export const InfoMessage = (message:string) => {
    
    return (
        <>
            {  enqueueSnackbar({ variant: 'info', message: `${message}` }) }
        </>
    );
}