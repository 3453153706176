import { ILoginResult } from "@interfaces/Data/ILogin";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getLocalStore, getSession , removeLocalStore, removeSession, setSession } from "@utils/Session";
import { Mutex } from 'async-mutex';
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({

    baseUrl:"https://amazon.ioshop.com.tw/janitor" ,
    mode:"cors",
    prepareHeaders:(headers) => {
        headers.set("Content-Type" , `application/json`);
        const _SID = getSession("_SID");
        if(_SID){
            headers.set("Authorization" , `Bearer ${_SID}`);
        }
        return headers;
    }
});

export const baseQueryWithIntercept: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();

    let result: QueryReturnValue<
        any,
        FetchBaseQueryError,
        FetchBaseQueryMeta
    > = await baseQuery(args, api, extraOptions);


    const { error, meta } = result;

    if (error) {

        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                const { status } = error as FetchBaseQueryError;
                const { request } = meta as FetchBaseQueryMeta;
                const url: string = request.url;
                
                let apiPath = url.split("/");

                if( (status === 401 && apiPath[apiPath.length-1] === "refreshToken") || (status === 999 && apiPath[apiPath.length-1] === "refreshToken")){
                    window.location.href = '/login';
                } else if (status === 401 && apiPath[apiPath.length-1] !== "login" ){
                    
                    const refreshToken = getSession("_RSID") || getLocalStore("_RSID");
                    console.log( "refresh token" , refreshToken);
                    const refreshResult = await baseQuery(
                        { 
                            credentials: 'include', 
                            url: '/auth/web/refreshToken' ,
                            method:'post' ,
                            body:{ refreshToken:refreshToken }
                        },
                        api,
                        extraOptions
                    );

                    console.log( "refresh refreshResult" , refreshResult);

                    if (refreshResult.data ) {
                        // Retry the initial query
                        setSession("_SID" , (refreshResult.data as ILoginResult ).token);
                        result = await baseQuery(args, api, extraOptions);
                    } else {
                        removeSession("_SID");
                        removeSession("_RSID");
                        removeLocalStore("_RSID");
                        window.location.href = '/login';
                    }
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};

