/** 儲存 sessionStorage */
export const setSession = (name: string, content: string | {} | []) => {
    if (!name) {
        return false;
    }
    let param = '';
    if (typeof content !== 'string') {
        param = JSON.stringify(content)
    } else {
        param = content
    }
    window.sessionStorage.setItem(name, param)
}
  
/** 獲取sessionStorage */
export const getSession = (name: string) => {
    if (!name) {
        return false
    }
    try {
        const json = window.sessionStorage.getItem(name) || '';
        return JSON.parse(json);
    } catch (error) {
        return window.sessionStorage.getItem(name);
    }
}
  
/** 刪除sessionStorage */
export const removeSession = (name: string) => {
    if (!name) {
      return false
    }
    window.sessionStorage.removeItem(name)
}
  
/** 刪除所有的sessionStorage */
export const removeAllSession = () => {
    window.sessionStorage.clear()
}
  
/** 儲存localStorage */
export const setLocalStore = (name: string, content: string | {} | []) => {
    if (!name) {
        return false
    }
    let param = ''
    if (typeof content !== 'string') {
        param = JSON.stringify(content)
    } else {
        param = content
    }
    window.localStorage.setItem(name, param)
}
  
/** 獲取localStorage */
export const getLocalStore = (name: string) => {
    if (!name) {
        return false
    }
    try {
        const json = window.localStorage.getItem(name) || ''
        return JSON.parse(json)
    } catch (error) {
        return window.localStorage.getItem(name)
    }
}
  
/** 刪除localStorage */
export const removeLocalStore = (name: string) => {
    if (!name) {
        return false
    }
    window.localStorage.removeItem(name)
}
  
/** 刪除所有的localStorage */
export const removeAllLocalStore = () => {
    window.localStorage.clear()
}