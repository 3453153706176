import Face from "@icon/Face";
import Logout from "@icon/Logout";
import { Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useLogoutMutation } from "@redux/features/Auth/authApi";
import { removeAllSession, removeAllLocalStore } from "@utils/Session";
import {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProfileSection = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    const navigate = useNavigate();

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [
        logout ,
        {
            isSuccess : isLogoutSuccess,
            isError : isLogoutError,
            error : logoutError
        }
    ] = useLogoutMutation();

    useEffect(() => {
        if(isLogoutSuccess){
            removeAllSession();
            removeAllLocalStore();
            navigate("/login");
        } else if( isLogoutError || logoutError ){
            removeAllSession();
            removeAllLocalStore();
            navigate("/login");
        }
        
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isLogoutSuccess , isLogoutError , logoutError]);

    const handleLogout = () => {
        logout();
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Janitor 帳戶">
                    <IconButton
                        onClick={handleToggle}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}> <Face color="info"/> </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={handleLogout}
                >
                    
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                        Logout
                </MenuItem>
            </Menu>
        </>
    );
}

export default ProfileSection;