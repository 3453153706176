import Feed from "@icon/Feed";
import { RouterType } from "@interfaces/Props/ISidebarItemProps";


const menuRoutes: RouterType[] = [
    {
        path: "",
        state: "record",
        sidebarProps: {
            displayText: "報表",
            icon: <Feed />
        },
        child: [
            {
                path: "/admin/fields/%s/record/indoor",
                state: "record.indoor",
                sidebarProps: {
                    displayText: "室內巡更紀錄"
                },
            },
            {
                path: "/admin/fields/%s/record/outdoor",
                state: "record.outdoor",
                sidebarProps: {
                    displayText: "室外巡更紀錄"
                }
            }
        ]
    }
];

export default menuRoutes;