
import { Box, Drawer, List } from "@mui/material";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import menuRoutes from "@routes/menuRoutes";

const Sidebar = () => {

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: "250px",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: "250px",
                    boxSizing: "border-box",
                    borderRight: "0px",
                }
            }}
            >
                <Box sx = {{ marginTop:"88px" }}/>
                <List disablePadding>
                    { menuRoutes.map((route, index) => (
                        route.sidebarProps ? (
                            route.child ? (
                            <SidebarItemCollapse item={route} key={index} />
                            ) : (
                            <SidebarItem item={route} key={index} />
                            )
                        ) : null
                    ))}
                </List>
        </Drawer>
    )
}

export default Sidebar;