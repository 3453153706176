import { lazy } from 'react';
import { RouteObject , Navigate, Outlet, useLocation } from 'react-router-dom';
import MainLayout from '@layout/MainLayout';
import SubLayout from '@layout/SubLayout';
import LoginLayout from '@layout/LoginLayout';
import LauncherLoading from '@pages/LauncherLoading';
import { getLocalStore, getSession } from '@utils/Session';
import Loadable from '@components/Loadable';

const FieldsView = Loadable(lazy(() => import("@pages/Fields/Fields")));
const IndoorCheckInReport = Loadable(lazy(() => import("@pages/Records/IndoorCheckInReport")));
const OutdoorCheckInReport = Loadable(lazy(() => import("@pages/Records/OutdoorCheckInReport")));
const NotFoudLayout = Loadable(lazy(() => import("@pages/NotFoud/index")));

const RequireAuth = () => {
    const location = useLocation();
    const _SID = getSession("_SID");
    const _RSID = getSession("_RSID") || getLocalStore("_RSID");
    if(!_SID && !_RSID){
        
        return <Navigate to="/login" state={{ from: location }} />;
        
    }
    return <Outlet />;
}

const MainRoutes:Array<RouteObject> = [
    {
        path: '/',
        element: <LauncherLoading />
    },
    {
        path: '/login',
        element: <LoginLayout />
    } ,
    {
        path: '*',
        element: <NotFoudLayout />
    } ,
    {
        path: '/admin',
        element: <MainLayout><RequireAuth /></MainLayout>,
        children: [
            {
                path: '',
                element: <FieldsView />,
            } ,
            {
                path: 'fields/:bssid',
                element: <SubLayout><Outlet /></SubLayout>,
                children:[
                    {
                        path: 'record/indoor',
                        element: <IndoorCheckInReport />,
                    } ,
                    {
                        path: 'record/outdoor',
                        element: <OutdoorCheckInReport />,
                    }
                ]
            }
        ]
    } ,
]

export default MainRoutes;