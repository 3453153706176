import { AppBar, Box, CssBaseline, Toolbar , useTheme , styled  } from "@mui/material";


// import Header from "./Header";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { setSelectField } from "@redux/features/User/userSlice";
import { useAppDispatch } from "@redux/hooks";
import Header from "./Header";


// styles
const Main = styled('main', {})(({theme}) => ({
    ...theme.typography.mainContent,
    
}));

type Props = {
    window?: () => Window;
    children?: React.ReactNode;
};

const MainLayout = (props:Props) => {

    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default ,
                    zIndex: (theme) => theme.zIndex.drawer + 1 
                }}
            >
                <Toolbar >
                    <Header />
                </Toolbar>
            </AppBar>
            <Main theme={theme} >
                {props.children}
            </Main>
        </Box>
    );
}

export default MainLayout;