import ISidebarItemProps from "@interfaces/Props/ISidebarItemProps";
import { ListItemButton, ListItemIcon, ListItemText, Typography, Collapse, List } from "@mui/material";
import { selectMenuState } from "@redux/features/Menu/menuSlice";
import { useAppSelector } from "@redux/hooks";
import { useEffect, useState } from "react";
import ExpandLessOutlinedIcon from '@icon/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@icon/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";

const SidebarItemCollapse = ({ item }: ISidebarItemProps) => {

    const [open, setOpen] = useState(true);

    const menuState = useAppSelector(selectMenuState);

    useEffect(() => {
        if (menuState.includes(item.state)) {
            setOpen(true);
        }
    }, [menuState, item]);

    return (
        item.sidebarProps ? (
            <>
                <ListItemButton
                    // onClick={() => setOpen(!open)}
                    sx={{
                        "&: hover": {
                            backgroundColor: "primary.200"
                        },
                        paddingY: "12px",
                        paddingX: "24px" ,
                        
                    }}
                >
                    <ListItemIcon sx={{
                        color: "orange.dark"
                    }}>
                        {item.sidebarProps.icon && item.sidebarProps.icon}
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography>
                                {item.sidebarProps.displayText}
                            </Typography>
                        }
                    />
                    {open ? <ExpandLessOutlinedIcon color="primary" /> : <ExpandMoreOutlinedIcon color="primary"/>}
                </ListItemButton>
                <Collapse in={open} timeout="auto">
                    <List>
                    {item.child?.map((route, index) => (
                        route.sidebarProps ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={index} />
                        ) : (
                            <SidebarItem item={route} key={index} />
                        )
                        ) : null
                    ))}
                    </List>
                </Collapse>
            </>
          ) : null
    )
}

export default SidebarItemCollapse;