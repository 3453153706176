import { SvgIconProps, SvgIcon } from "@mui/material";

const ExpandLessOutlined = (props: SvgIconProps) =>{
    return (
        <SvgIcon {...props}>
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/>
        </SvgIcon>
    );
}
export default ExpandLessOutlined;