import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import themes from '@themes/index';
import Routes from '@routes/index';


function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes()}>
        
        <CssBaseline />
        <Routes />
      </ThemeProvider>
       
    </StyledEngineProvider>
  );
}

export default App;
